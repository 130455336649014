import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The basic element of a button is a label. Labels inform users of the action that will occur if they click the button.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button1.svg",
      "alt": "anatomy button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Icons left or right :`}</strong>{` Icon that can be positioned either before (leading) or after (trailing) the Label.`}</li>
      <li parentName="ol"><strong parentName="li">{`Text labels :`}</strong>{` The text attributed to the Button that provides context.`}</li>
      <li parentName="ol"><strong parentName="li">{`Container :`}</strong>{` Box from label, icon and as clickable area of button.`}</li>
    </ol>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button2.svg",
      "alt": "button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Icons :`}</strong>{` Icon that positioned the centre of the container. If you are using an Icon Button an icon should always be supplied..`}</li>
      <li parentName="ol"><strong parentName="li">{`Container :`}</strong>{` The Icon Button container.`}</li>
    </ol>
    <br />
    <br />
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button3.svg",
      "alt": "button guideline"
    }}></img>
    <p>{`Button can consist of an icon on its own or an icon paired with a text label. Always ensure that the icon you select for your label clearly communicates the label action. Icons paired with text labels should directly support the text label.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use direct and concise button labels with short label with one of icon to provide the label context" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use multiple icons or unnecessarily long button labels make the users confused mean the action in button" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button41.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Buttons allow users to make choices, take actions, and help guide around an interface with a single tap. Buttons also allows user to communicate actions that users can take. They are typically placed throughout your User Interface.`}</p>
    <p><strong parentName="p">{`Common places buttons appear:`}</strong>{` Dialogs, Forms, Toolbars, etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        To display several details about a single idea like a transfer at an
        airport.
      </li>
      <li>
        To use common visual cues (icons and colors) to help users scan the
        details.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> When you have many actions at once. </li>
      <li> You want to offer a less important action. </li>
      <li> To make text inside paragraphs or lists actionable. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A button is a box area or text that communicates and triggers user actions when clicked or selected. Buttons are interactive elements.In this layout a Solid button is used to call out a primary action while a Ghost button is used to call out a secondary action.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use buttons with different variant to convey actions with different levels of importance" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use same variant between to convey actions with different levels of importance" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h3><strong parentName="h3">{`Margin and Padding`}</strong></h3>
    <p>{`Buttons with text and text with labels have a minimum width of 107 pixels. Wider buttons and buttons with an icon only label must maintain a minimum 16 pixel padding on the left and right. This ensures that the label is legible and clear. In special cases, such as with loader labels, additional padding to the right is required.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/button7.svg",
      "alt": "button margin and padding"
    }}></img>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`Whenever writing content for the button, one should remember its purpose of triggering action and moving the experience. That’s why every word in it has to be clear and expected.`}</p>
    <p><strong parentName="p">{`Always start with a verb`}</strong></p>
    <p>{`Choose a strong, simple word that induces action. You may make it a single-word button on a more common direct action (e.g. “Lanjut”, “Batal”) or a complete phrase with nouns and adverbs if necessary and within the character limit.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <p>{`In some cases, you might need to mention two verbs to address an action and its consequent action.  Please keep it short and clear, and give a necessary conjunction.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="2:1" colLg="12" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <p><strong parentName="p">{`Use Title Case`}</strong></p>
    <p>{`Title Case makes the button easier for users to scan and comprehend the content quickly. Otherwise, conjunctions, propositions, and articles should be applied in lowercase.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <p><strong parentName="p">{`Keep it under 20 characters`}</strong></p>
    <p>{`Refrain from counting the copy length based on how many words you use since its result tends to be inconsistent. When working on bilingual design, keep the copy around the same length in both languages.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content7.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <p><strong parentName="p">{`Cut conjunctions, propositions, and articles if necessary`}</strong></p>
    <p>{`A shorter phrase leads to a quicker decision. Otherwise, if the copy sounds wrong after taking out those words, you might keep it as long as it’s still within the character limit.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content8.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/content9.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <p><strong parentName="p">{`Use icon sparingly`}</strong></p>
    <p>{`While it might help to catch the user’s attention or clarify the button’s action, adding an icon also potentially impacts the user’s cognitive load. Prioritize using only plain words.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      